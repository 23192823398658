<template>
    <div class="login-container">
        <img
            src="../../assets/img/newerp_button1.png"
            class="left-logo"
            alt=""
        />
        <div class="login-box">
            <!-- <div class="avatar_box">
        <img src="../../assets/img/cebsb_logo.png" alt="" />
      </div> -->
            <div class="login-txt">登录</div>
            <el-form
                ref="loginFormRef"
                class="login_form"
                :rules="loginFormRules"
                :model="loginForm"
            >
                <el-form-item prop="phone">
                    <el-input
                        class="input"
                        v-model="loginForm.phone"
                        prefix-icon="iconfont icon-user"
                        placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item prop="code">
                    <el-input
                        class="input"
                        v-model="loginForm.code"
                        prefix-icon="iconfont icon-key"
                        type="code"
                        placeholder="请输入短信验证码"
                    >
                        <el-button
                            class="send-btn"
                            :loading="sending"
                            :disabled="sendDisabled"
                            @click="sendSmsClicked"
                            slot="suffix"
                            >{{ sendButtonText }}</el-button
                        >
                    </el-input>
                </el-form-item>
                <el-form-item class="btns">
                    <el-button
                        class="login-btn"
                        type="primary"
                        @click="mainLogin"
                        @keyup.enter="mainLogin(e)"
                        >登录</el-button
                    >
                </el-form-item>
            </el-form>
        </div>
        <!-- 选择用户的Dialog -->
        <el-dialog
            title="选择身份"
            :visible.sync="selectUserDialogViaible"
            width="50%"
        >
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="userList"
                border
                stripe
            >
                <!-- <el-table-column label="部门" prop="deptName"></el-table-column> -->
                <el-table-column label="岗位" prop="name"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            style="cursor: pointer"
                            size="mini"
                            @click="userLogin(scope.row)"
                            >登录</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { onSendSms, chnEngLoginRequest, listByLogin, setIdentity } from '../../http/api'
// import storage from '../../store/storage'
export default {
  data () {
    return {
      loginForm: {
        phone: '',
        code: ''
      },
      loginFormRules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ]
      },
      userList: [],
      selectUserDialogViaible: false,
      sending: false,
      sendDisabled: false,
      loading: false,
      timer: 0
    }
  },
  mounted () {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed () {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  },
  created () {
    localStorage.removeItem('Authorization') // 移除token，跳转至登录
    const lastSendTime = window.sessionStorage.getItem('last-send-time')
    if (lastSendTime) {
      const timer = 60 - this.$moment(new Date()).diff(this.$moment(lastSendTime), 'seconds')
      if (timer > 0) this.sendDisabled = true
      this.timer = timer > 0 ? timer : 0
    }
  },
  methods: {
    // 点击回车键登录
    keyDown (e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.mainLogin() // 定义的登录方法
      }
    },
    resetLoginForm () {
      this.$refs.loginFormRef.resetFields()
    },
    // 选择身份后登录
    userLogin (erpUserData) {
      console.log('erpUserData', erpUserData)
      setIdentity({ id: erpUserData.id }).then((res) => {
        console.log('loginRes', res)
        this.$store.commit('SET_JOBINFO', erpUserData)
        this.$store.commit('SET_PERMISSION', res.data.roleMenus)
        this.$store.commit('SET_MENULIST', res.data.directoryMenus)
        this.$router.push('/home')
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 获取短信
    sendSmsClicked () {
      onSendSms({ phone: this.loginForm.phone, codeType: this.$chnEngStatusCode.LOGINSMSTYPE }).then((res) => {
        this.$message.success('短信发送成功，请注意查收')
        window.sessionStorage.setItem('loginLast-send-time', new Date())
        this.timer = 60
        this.sendDisabled = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 登录
    mainLogin () {
      const formData = new FormData()
      formData.append('phone', this.loginForm.phone)
      formData.append('code', this.loginForm.code)
      formData.append('type', this.$chnEngStatusCode.LOGINTYPE)
      formData.append('codeType', this.$chnEngStatusCode.LOGINSMSTYPE)
      this.$refs.loginFormRef.validate(async valid => {
        if (!valid) return
        this.isLogonState = true
        chnEngLoginRequest(formData).then((res) => {
          console.log('mainLoginres', res)
          this.getUserRoleList()
        }).catch(() => {
          this.isLogonState = false
        })
      })
    },
    // 获取用户岗位信息
    getUserRoleList () {
      listByLogin().then((res) => {
        switch (res.data.length) {
          case 0:
            return this.$message.error('该账号下未设置身份信息')
          case 1:
            this.userLogin(res.data[0])
            break
          default:
            this.userList = res.data
            this.selectUserDialogViaible = true
            break
        }
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  },
  computed: {
    sendButtonText () {
      if (this.timer === 0) {
        return '发送验证码'
      } else {
        return `${this.timer}秒后重发`
      }
    }
  },
  watch: {
    timer (num) {
      if (num > 0) {
        setTimeout(() => {
          this.timer = --num
        }, 1000)
      } else {
        this.sendDisabled = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.login-container {
    // background-color: #2b5b6b;
    // background-image: url(https://cdn.spbcn.org/spbcnWeb/spbcn/HOME_PIC17_2022092717.jpg);
    // background-size: contain;
    // background-position: center;
    background-color: #0f173d;
    background-image: linear-gradient(155deg, #0f173d, #0f173d 60%, #5c0404);
    background-repeat: no-repeat;
    height: 100%;
    .left-logo {
        width: 234px;
        height: auto;
        margin-left: 50px;
        margin-top: 40px;
    }
    .login-btn {
        width: 180px;
        border-radius: 40px;
        background-color: #cbae72;
        border-color: #cbae72;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .login-txt {
        font-size: 28px;
        color: #333;
        margin-left: 30px;
        margin-top: 40px;
        padding-bottom: 4px;
        border-bottom: 3px solid #cbae72;
        width: 66px;
    }
    .send-btn {
        border: none;
        &:hover,
        &:focus {
            color: #cbae72;
            background-color: transparent;
        }
    }
    .input ::v-deep .el-input__inner {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #dcdfe6;
    }
    .login-box {
        width: 450px;
        height: 360px;
        background-color: #fff;
        border-radius: 12px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .avatar_box {
            height: 130px;
            width: 130px;
            border: 1px solid #eee;
            border-radius: 50%;
            padding: 10px;
            box-shadow: 0 0 10px #ddd;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;

            img {
                width: 100%;
                height: auto;
            }
        }

        .login_form {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;

            .btns {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>
