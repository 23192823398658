<template>
    <el-container class="home-container">
        <el-header>
            <div>
                <img :src="require('@/assets/img/newerp_icon1.png')"  alt="" fit="contain"/>
                <span>英文ERP管理系统</span>
            </div>
            <div>
              <!-- <el-tooltip class="item" effect="dark" content="数据看板" placement="top-start">
                <div class="downLoadClass" @click="downListClicked2">
                    <i class="el-icon-data-line myicon"></i>
                </div>
              </el-tooltip> -->
              <el-tooltip class="item" effect="dark" content="下载历史" placement="top-start">
                <div class="downLoadClass" @click="downListClicked">
                    <!-- <el-image
                        style="width: 30px; height: 30px"
                        :src="require('@/assets/img/download_list.png')"
                    ></el-image> -->
                    <i class="el-icon-download myicon"></i>
                </div>
              </el-tooltip>
                <el-dropdown @command="handleCommand">
                    <span class="username" style="color: white"
                        >{{userJobInfo.name}}
                        <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="changeRole"
                            >切换身份</el-dropdown-item
                        >
                        <el-dropdown-item command="logOut"
                            >退出登录</el-dropdown-item
                        >
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-header>
        <el-container>
            <el-aside :width="menuCollapse ? '64px' : '200px'">
                <div class="toggle-button" @click="toggleCollapse">|||</div>
                <el-menu
                    ref="menuRef"
                    background-color="#333744"
                    text-color="#fff"
                    active-text-color="#409Bff"
                    unique-opened
                    :collapse="menuCollapse"
                    :collapse-transition="false"
                    router
                    @open="menuOpen"
                >
                    <!-- 一级菜单 -->
                    <template v-for="item in subMenuList">
                        <el-submenu
                            v-if="item.childMenu && item.childMenu.length"
                            :index="item.id"
                            :key="item.id"
                        >
                            <template slot="title"
                                ><i :class="iconsObj[item.code]"></i
                                ><span>{{ item.name }}</span></template
                            >
                            <!-- 二级菜单 -->
                            <template v-for="itemChild in item.childMenu">
                                <el-submenu
                                    v-if="
                                        itemChild.childMenu &&
                                        itemChild.childMenu.length
                                    "
                                    :index="itemChild.id"
                                    :key="itemChild.id"
                                >
                                    <template slot="title"
                                        ><span>{{
                                            itemChild.name
                                        }}</span></template
                                    >

                                    <!-- 三级菜单 -->
                                    <el-menu-item
                                        v-for="itemChild_Child in itemChild.childMenu"
                                        :key="itemChild_Child.linkUrl"
                                        :index="itemChild_Child.linkUrl"
                                    >
                                        <span slot="title">{{
                                            itemChild_Child.name
                                        }}</span></el-menu-item
                                    >
                                </el-submenu>

                                <el-menu-item
                                    v-else
                                    :index="itemChild.linkUrl"
                                    :key="itemChild.linkUrl"
                                    ><span slot="title">{{
                                        itemChild.name
                                    }}</span></el-menu-item
                                >
                            </template>
                        </el-submenu>

                        <el-menu-item
                            v-else
                            :key="item.linkUrl"
                            :index="item.linkUrl"
                            ><i :class="iconsObj[item.code]"></i
                            ><span slot="title">{{
                                item.name
                            }}</span></el-menu-item
                        >
                    </template>
                </el-menu>
            </el-aside>
            <el-main>
                <keep-alive>
                    <router-view v-if="$route.meta.keepAlive"></router-view>
                </keep-alive>
                <router-view v-if="!$route.meta.keepAlive"></router-view>
            </el-main>
        </el-container>
        <!-- 选择用户的Dialog -->
        <el-dialog
            title="选择身份"
            :visible.sync="selectUserDialogViaible"
            width="50%"
        >
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="roleList"
                border
                stripe
            >
                <el-table-column label="部门" prop="deptName"></el-table-column>
                <el-table-column label="岗位" prop="rname"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="mini"
                            @click="userLogin(scope.row)"
                            >登录</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </el-container>
</template>

<script>
import { listByLogin, setIdentity } from '@/http/api'
export default {
  data () {
    return {
      iconsObj: {
        race_management: 'el-icon-setting',
        exam_management: 'el-icon-school',
        b_data_management: 'el-icon-user',
        c_data_management: 'el-icon-document',
        product_management_home: 'el-icon-dish',
        academic_management: 'el-icon-collection',
        system_management: 'el-icon-help',
        ziranpindu: 'el-icon-s-claim',
        pczx: 'el-icon-star-on'
      },
      subMenuList: [
      ],
      menuCollapse: false,
      menuOpenIndex: '',
      selectUserDialogViaible: false,
      roleList: []
    }
  },
  created () {
    this.navActivePath = window.sessionStorage.getItem('navActivePath')
    this.subMenuList = this.$store.state.menuList
    this.userJobInfo = this.$store.state.userJobInfo
  },
  methods: {
    // 下载历史点击事件
    downListClicked () {
      console.log('sssssssss')
      this.$router.push('/home/downListHistory')
    },
    downListClicked2 () {
      this.$router.push('/welcome')
    },
    toggleCollapse () {
      this.menuCollapse = !this.menuCollapse
    },
    saveNavActivePath (navActivePath) {
      console.log(navActivePath)
      window.sessionStorage.setItem('navActivePath', navActivePath)
      this.navActivePath = navActivePath
    },
    workTable () {
      window.sessionStorage.setItem('isFirstLogin', false)
      if (this.navActivePath === '') {
        this.$refs.menuRef.close(this.menuOpenIndex)
        this.navActivePath = ''
        window.sessionStorage.setItem('navActivePath', '')
      }
      switch (this.roleWildcard) {
        case 'A2':
          this.$router.push('/workTable/a2')
          break
        case 'A1':
          this.$router.push('/workTable/a1')
          break
        case 'B1':
          switch (this.deptWildcard) {
            case 'sspcn':
              this.$router.push('/workTable/b1Sspcn')
              break
            case 'baby':
              this.$router.push('/workTable/b1Baby')
              break
            case 'itso':
              this.$router.push('/workTable/b1Itso')
              break
          }
          break
      }
    },
    menuOpen (value) {
      this.menuOpenIndex = value
    },
    handleCommand (command) {
      switch (command) {
        case 'logOut':
          window.sessionStorage.clear()
          this.$router.push('/login')
          break
        case 'changeRole':
          this.getUserRoleList()
          break
      }
    },
    getUserRoleList () {
      listByLogin().then((res) => {
        this.roleList = res.data
        this.selectUserDialogViaible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 选择身份后登录
    userLogin (erpUserData) {
      setIdentity({ id: erpUserData.id }).then((res) => {
        console.log('loginres', res)
        this.$store.commit('SET_JOBINFO', erpUserData)
        this.$store.commit('SET_PERMISSION', res.data.roleMenus)
        this.$store.commit('SET_MENULIST', res.data.directoryMenus)
        this.$router.push('/home')
        this.$router.go(0) // 刷新页面
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.downLoadClass{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-container {
    height: 100%;

    .el-header {
        background-color: #373d41;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-size: 20px;
        > div {
            display: flex;
            align-items: center;
            img {
                height: 50px;
            }
            .username {
                font-size: 16px;
                margin-right: 15px;
            }
            span {
                padding-left: 15px;
            }
        }
    }

    .el-aside {
        background-color: #333744;

        .toggle-button {
            background-color: #4a5064;
            font-size: 10px;
            line-height: 24px;
            color: #fff;
            text-align: center;
            letter-spacing: 0.2em;
            cursor: pointer;
        }

        .el-menu {
            border-right: none;
        }

        .iconfont {
            margin-right: 10px;
        }
    }

    .el-main {
        background-color: #eaedf1;
        height: calc(100vh - 60px);
    }
}
.myicon{
  color: #409EFF;
}
</style>
