<template>
  <div class="welcome-container">
    <div class="bg"></div>
    <el-row class="row">
      <el-col class="h1"> WELCOME {{ username }} TO</el-col>
      <el-col class="txt">英文拼词大会 · ERP管理系统</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: window.sessionStorage.getItem('username')
    }
  }
}
</script>
<style scoped lang="less">
.welcome-container {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 40px;
  padding-left: 40px;
}
.bg {
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/newerp_icon1.png);
  background-size: auto 90%;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  position: absolute;
  top: 0;
  right: 0;
}
.txt {
  font-size: 60px;
  font-weight: 600;
  color: #464545;
  padding-bottom: 120px;
}
.h1 {
  font-weight: 400;
  font-size: 40px;
  color: #464545;
  margin-bottom: 20px;
}
.row {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
