<template>
  <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>下载记录</el-breadcrumb-item>
      </el-breadcrumb>
      <el-page-header @back="goBack"></el-page-header>
      <!-- 筛选 -->
      <!-- <el-card style="margin-top: 15px">
          <el-row class="title_row">
              <el-col>
                  <span class="title_class">日志筛选</span>
              </el-col>
          </el-row>
          <el-form
              :model="queryForm"
              class="filter_Form"
              label-width="120px"
              ref="queryFormRef"
          >
              <el-row :gutter="10">
                  <el-col :span="6">
                      <el-form-item label="创建人" prop="userName">
                          <el-input
                              v-model="queryForm.userName"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="请求地址" prop="url">
                          <el-input
                              v-model="queryForm.url"
                              placeholder="请输入"
                              clearable
                          ></el-input>
                      </el-form-item>
                  </el-col>
                  <el-col :span="6">
                      <el-form-item label="终端" prop="terminal">
                          <el-select
                              placeholder="请选择"
                              style="width: 100%"
                              v-model="queryForm.terminal"
                              clearable
                          >
                              <el-option
                                  v-for="item in dict_terminal"
                                  :key="item.dictCode"
                                  :label="item.dictLabel"
                                  :value="item.dictValue"
                              ></el-option>
                          </el-select>
                      </el-form-item>
                  </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="起止时间" prop="dateTimeRange">
            <el-date-picker
            style="width: 100%;"
              v-model="queryForm.dateTimeRange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="timestamp"
              clearable
            >
            </el-date-picker>
          </el-form-item>
                  </el-col>
              </el-row>
              <el-row :gutter="20" type="flex" justify="end">
                  <el-col :span="2">
                      <el-button
                          type="primary"
                          style="width: 100%"
                          @click="getDownLoadList('search')"
                          >查询</el-button
                      >
                  </el-col>
                  <el-col :span="2">
                      <el-button
                          type="primary"
                          plain
                          style="width: 100%"
                          @click="reset"
                          >重置</el-button
                      >
                  </el-col>
              </el-row>
          </el-form>
      </el-card> -->
      <!-- 日志列表 -->
      <el-card class="list_card" style="margin-top: 20px">
          <el-row class="title_row">
              <el-col :span="12">
                  <div class="list_title title_class">记录列表</div>
              </el-col>
          </el-row>
          <el-table
              header-cell-class-name="tableHeaderStyle"
              :data="dataList"
              border
              stripe
          >
              <el-table-column label="序号" type="index"></el-table-column>
              <el-table-column
                  label="名称"
                  prop="name"
              ></el-table-column>
              <el-table-column label="路径" prop="filePath"></el-table-column>
              <el-table-column label="OSS" prop="url"></el-table-column>
              <el-table-column
                  label="用户id"
                  prop="userId"
              ></el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryForm.pageNum"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="queryForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
          >
          </el-pagination>
      </el-card>
  </div>
</template>

<script>
import { downLoadList } from '@/http/api'
export default {
  data () {
    return {
      dict_terminal: this.$userInfo.getDataList(this, 'dict_terminal').then(value => { this.dict_terminal = value }),
      dataList: [],
      dateTimeRange: [],
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0
    }
  },
  created () {
    this.getDownLoadList()
  },
  methods: {
    getDownLoadList (type) {
      if (type === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      downLoadList(this.queryForm).then((res) => {
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDownLoadList()
    },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDownLoadList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDownLoadList()
    }
  }
}
</script>

<style scoped>
.a-btn {
  color: #409eff;
  font-size: 14px;
}
.row {
  display: flex;
  margin-bottom: 12px;
}
</style>
